import emoji from 'react-easy-emoji';

import galiciaLogo from './assets/img/icons/common/galiciaLogo.png'
import cedeiraLogo from './assets/img/icons/common/cedeiraLogo.png'
import logoMSM from './assets/img/icons/common/logoMSM.png'
import logoMeli from './assets/img/icons/common/logoMeli.png'

export const greetings = {
    "name": "Joaquin Fernandez Cedeira",
    "title": "Hola, soy Joaco",
    "description": "Soy un programador Fullstack con más de 5 años de experiencia en el desarrollo de aplicaciones backend y frontend. Actualmente me encuentro trabajando en MercadoLibre, donde formo parte del equipo FuryCoreExp en el área de Cloud & Platform.\n Me apasiona la tecnología y me encanta mantenerme actualizado en las últimas tendencias en programación y desarrollo.",
    "resumeLink": "https://drive.google.com/file/d/1hYt6qmYB11wFXMTog31DwBSlUm3LI4XX/view?usp=sharing"
}

export const openSource = {
  githubUserName: 'ferced',
};

export const contact = {
  
}

export const socialLinks = {
    "instagram": "https://www.instagram.com/joaco.ced",
    "github": "https://github.com/ferced",
    "linkedin": "https://www.linkedin.com/in/joaquin-fernandez-cedeira-75949012b/"
}

export const skillsSection = {
    title: '¿Qué hago?',
    subTitle: 'Me especializo en la implementación de soluciones escalables y eficientes para mejorar el rendimiento y la calidad del software. Trabajo en colaboración con otros desarrolladores y stakeholders utilizando metodologías ágiles y herramientas de Cloud Computing. Además, tengo experiencia en el desarrollo de APIs y servicios web utilizando diferentes protocolos. Estoy comprometido con el éxito del proyecto y siempre busco nuevos desafíos para seguir creciendo profesionalmente.',
    skills: [
      emoji(
        '⚡ Desarrollo aplicaciones FrontEnd, BackEnd y Mobile'
      ),
      emoji(
        '⚡ AWS, DataDog, NewRelic y otras herramientas de Cloud Computing'
      ),
      emoji(
        '⚡ Docker, Terraform, Github Actions y otras herramientas de CI/CD'
      ),
    ],
    
    softwareSkills: [
      {
        skillName: 'go',
        fontAwesomeClassname: 'logos:go',
      },
      {
        skillName: 'python',
        fontAwesomeClassname: 'logos:python',
      },
      {
        skillName: 'JavaScript',
        fontAwesomeClassname: 'logos:javascript',
      },
      {
        skillName: 'reactjs',
        fontAwesomeClassname: 'vscode-icons:file-type-reactjs',
      },
      {
        skillName: 'nodejs',
        fontAwesomeClassname: 'logos:nodejs-icon',
      }, 
      {
        skillName: 'git',
        fontAwesomeClassname: 'logos:git-icon',
      },
      {
        skillName: 'C-Sharp',
        fontAwesomeClassname: 'vscode-icons:file-type-csharp2',
      },
      {
        skillName: 'docker',
        fontAwesomeClassname: 'logos:docker-icon',
      },
      {
        skillName: 'html-5',
        fontAwesomeClassname: 'vscode-icons:file-type-html',
      },
      {
        skillName: 'css3',
        fontAwesomeClassname: 'vscode-icons:file-type-css',
      },
      {
        skillName: 'sass',
        fontAwesomeClassname: 'logos:sass',
      },
      {
        skillName: 'flutter',
        fontAwesomeClassname: 'logos:flutter',
      },
      {
        skillName: 'dart',
        fontAwesomeClassname: 'logos:dart',
      },
      {
        skillName: 'npm',
        fontAwesomeClassname: 'vscode-icons:file-type-npm',
      },
      {
        skillName: 'sql-database',
        fontAwesomeClassname: 'vscode-icons:file-type-sql',
      },
      {
        skillName: 'firebase',
        fontAwesomeClassname: 'logos:firebase',
      },
  
    ],
}

export const LanguageSkillBars = [
  {
    Stack: 'Go',
    progressPercentage: '95',
  },
  {
    Stack: 'ReactJS',
    progressPercentage: '95',
  },
  {
    Stack: 'Python', 
    progressPercentage: '90', 
  },
  {
    Stack: 'C#',
    progressPercentage: '90',
  },
  {
    Stack: 'Ruby',
    progressPercentage: '85',
  },
  {
    Stack: 'Flutter',
    progressPercentage: '60',
  },
  {
    Stack: 'Dart',
    progressPercentage: '60',
  },

]

export const SkillBars = [
    {
      Stack: 'Backend', 
      progressPercentage: '90', 
    },
    {
      Stack: 'architecture',
      progressPercentage: '90',
    },
    {
      Stack: 'Frontend/Design',
      progressPercentage: '70',
    },
   
  ]

export const educationInfo = [
  
    {
      schoolName: 'UNGS - Universidad Nacional del General Sarmiento',
      subHeader: 'Licienciatura en sistemas (en progreso)',
      duration: 'Marzo 2016 - Octubre 2018',
    },
    {
      schoolName: 'PMI - Project Management Institute',
      subHeader: 'Metodología de Administración de Proyectos',
      duration: 'Septiembre 2017 - Diciembre 2017'
    },
    {
      schoolName: 'EducacionIT',
      subHeader: 'EducacionIT: Introducción a C#, Introducción al Paradigma de Objetos, Introducción a Base de datos, Web APIs, SQL y C# .NET',
      duration: 'SEPTIEMBRE 2018 - AGOSTO 2020',
    },
    {
      schoolName: 'Club Atlético San Miguel, San Miguel',
      subHeader: 'Bachiller en Ciencias Sociales',
      duration: 'MARZO 2004 - DICIEMBRE 2015'
    }
]

export const experience = [
  {
    role: 'Backend Developer',
    company: 'Mercado Libre',
    companylogo: logoMeli,
    date: 'Septiembre 2021 – Actualidad',
    descBullets: [
      'Go Developer (Cloud&Platform)',
      'FuryCoreExp team. (equipo de desarrollo responsable de mantener y evolucionar Fury)',
      'Fury-Api (API de Fury).', 
      'Fury-Squid (sistema de usuarios para Fury).',
      'Fury-Florida (sistema de autorizaciones para Fury).',
      'Fury-Tiger (sistema de autenticacion para Fury).',
    ],
  },
  {
    
    role: 'Full Stack Developer',
    company: 'Galicia',
    companylogo: galiciaLogo,
    date: 'Agosto 2018 – Septiembre 2021',
    desc:
      '',
    descBullets: [
      'Desarrollo de API Policy System',
      'Soporte y desarrollo del sistema Carga Centralizada de Tasas que se encarga de modificar las tasas del banco.',
      'Desarrollo de nuevas funcionalidades para el Sistema de Transferencias MEP.',
    ],
  },
  {
    role: 'Front-End Developer',
    company: 'Cedeira Software Factory',
    companylogo: cedeiraLogo,
    date: 'Agosto 2018 – Septiembre 2021',
    descBullets: [
      'Responsable de la creacion frontend para la aplicacion Facturas Electronicas.',
      'Desarrollo backend para la aplicacion Facturas Electronicas.',
      'Mantenimiento y evolcucion de diferentes aplicaciones'
    ],
  },
  {
    role: 'Full Stack Developer',
    company: 'Municipalidad de San Miguel',
    companylogo: logoMSM,
    date: 'Julio 2017 – Julio 2018',
    descBullets: [
      'Desarrollo de backend y frontend del sistema POS (Portal de obras Sociales) para hospitales publicos.',
      'Soporte y desarrollo del sistema GDP (Gestor De Proyectos) para la gestion de todos los proyectos del area.',
    ],
  },
 
]

export const projects = [
  {
    name: "Stockflex",
    desc: "Sistema de stock para una empresa de distribucion.",
    link: {
      name:"Github",
      url:"github.com/Ferced/Stockflex-fe"
    }
  },
  {
    name: "Pagina web para influencer",
    desc: "Pagina de cursos web para sus seguidores.",
    link: {
      name: "Noelia Sandoval",
      url:"https://www.instagram.com/noeliasandoval.nails/"
    }
  },
  {
    name: "Backtracking Knight's tour",
    desc: "Demostracion del algoritmo de Backtracking.",
    link: {
      name: "Github",
      url:"https://github.com/Ferced/Backtracking-Knight"
    }
  },
  {
    name: "Aplicacion Demo para NetSafe",
    desc: "Aplicacion demo para publicidad de NetSafe.",
    link: {
      name:"Github",
      url:"https://github.com/Ferced/app-Netsafe-demo"
    }
  },
]